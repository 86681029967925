<template>
  <div>
    <h1>Detail Class</h1>
    <!-- {{detailClass}} -->
    <a-row :gutter="[32, 16]">
      <a-col :md="24" :lg="14">
        <div class="card-full">
          <div class="detail">
            <a-menu class="mt-3" v-model="current" mode="horizontal">
              <a-menu-item key="user">CLASS INFORMATION</a-menu-item>
              <a-menu-item key="edit">EDIT CLASS</a-menu-item>
            </a-menu>

            <div v-if="current[0] === 'user'" class="mt-5">
              <a-row :gutter="[32, 32]" class="ml-2">
                <a-col :sm="12" :md="8">
                  <h3 class="type">Tingkat</h3>
                  <p class="value">{{detailClass.tingkat ? detailClass.tingkat : '-'}}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Simbol</h3>
                  <p class="value">{{detailClass.simbol ? detailClass.simbol : '-'}}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Students</h3>
                  <p class="value">{{students ? students : '-'}}</p>
                </a-col>
              </a-row>
              <a-row :gutter="[32, 32]" class="ml-2 mt-4">
                <a-col :sm="12" :md="8">
                  <h3 class="type">Tahun Ajaran</h3>
                  <p class="value">{{tahunAjaran ? tahunAjaran : '-'}}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Wali Kelas</h3>
                  <p class="value">{{waliKelas ? waliKelas : '-'}}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Zoom ID</h3>
                  <p class="value">{{detailClass.zoom_url ? detailClass.zoom_url : '-' }}</p>
                </a-col>
              </a-row>
            </div>
            <div v-else class="mt-4">
              <a-form @submit.prevent="updateProfile">
                <a-form-item label="Wali Kelas">
                  <a-select
                    show-search
                    option-filter-prop="children"
                    size="large"
                    style="width: 100%: height:40px;"
                    :filter-option="filterOption"
                    @change="handleChangeWaliKelas"
                  >
                    <a-select-option
                      v-for="(item, index) in listOfWaliKelas"
                      :key="index"
                      :value="item.id"
                      :default-value="item.nama === waliKelas ? item.id : ''"
                    >{{item.nama}}</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="Tahun Ajaran">
                  <a-select
                    size="large"
                    @change="handleChangeTahunAjaran"
                    style="width: 100%: height:40px;"
                  >
                    <a-select-option
                      v-for="(item, index) in listOfTahunAjaran"
                      :key="index"
                      :value="item.id"
                    >{{item.semester}} ({{item.tahun}})</a-select-option>
                  </a-select>
                </a-form-item>
                <a-button
                  class="btnChange"
                  type="primary"
                  html-type="submit"
                  size="large"
                  :loading="loadingUpdateProfile"
                  block
                >Update Now</a-button>
              </a-form>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :md="24" :lg="10">
        <div class="card-full">
          <div class="detail">
            <div class="cui__utils__heading mb-0">
              <strong>Change Zoom ID</strong>
            </div>
            <div class="text-muted mb-3">You can change Zoom ID here</div>
            <hr />
            <a-form @submit.prevent="updatePassword">
              <a-button
                class="btnChange"
                type="primary"
                html-type="submit"
                size="large"
                block
                :loading="loadingUpdatePassword"
              >Update Zoom ID</a-button>
            </a-form>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: ['user'],
      detailClass: {},
      listOfWaliKelas: [],
      listOfTahunAjaran: [],
      students: '',
      waliKelas: '',
      tahunAjaran: '',
      classOfStudent: '',
      loadingUpdateProfile: false,
      loadingUpdatePassword: false,
      loadingDelete: false,
      newPassword: '',
      newVerifyPassword: '',
    }
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('admin/FETCH_CLASS_BY_ID', { id })
        .then(data => {
          this.detailClass = data.kelas.data
          this.tahunAjaran = `${data.kelas.data.tahun_ajaran.semester} (${data.kelas.data.tahun_ajaran.tahun})`
          this.waliKelas = data.kelas.data.guru ? data.kelas.data.guru.nama : ''
          this.students = data.totalMurid
        })
      this.$store.dispatch('admin/FETCH_WALI_KELAS')
        .then(data => {
          this.listOfWaliKelas = data
        })
      this.$store.dispatch('admin/FETCH_TAHUN_AJARAN')
        .then(data => {
          this.listOfTahunAjaran = data
        })
    },
    handleChangeWaliKelas(value) {
      this.detailClass.id_guru = value
    },
    handleChangeTahunAjaran(value) {
      this.detailClass.id_tahun_ajaran = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    updateProfile() {
      this.loadingUpdateProfile = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to update {this.detailClass.tingkat}-{this.detailClass.simbol} information ?</div>
        ),
        onOk: () => {
          this.loadingUpdateProfile = false
          this.$store.dispatch('admin/UPDATE_CLASS', { data: this.detailClass })
            .then(res => {
              if (res === 200) {
                this.fetchData(this.detailClass.id)
                this.$notification.success({
                  message: 'Success',
                  description:
                    `${this.detailClass.tingkat}-${this.detailClass.simbol} information has been updated`,
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description:
                    `${this.detailClass.tingkat}-${this.detailClass.simbol} information has not been updated`,
                })
              }
            })
        },
        onCancel: () => {
          this.loadingUpdateProfile = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Update',
      })
    },
  },
  created() {
    const id = this.$route.params.id
    this.fetchData(id)
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 20px;
}

.detail .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}
</style>
